import React from "react";

import { LEGAL_DATA } from "data/pages";

import { Section } from "components/legal";
import { LegalPageLayout } from "components/layouts";
import { LayoutContext } from "../../../context";
import { DEFAULT_VALUES } from "../../../context/Layout";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";
import { uniqueId } from "lodash";

const PrivacyPolicy: React.FC = () => {
  const data = LEGAL_DATA.PAGE_CONTENTS.privacyPolicy;
  const { updateContext } = React.useContext(LayoutContext);

  React.useEffect(() => {
    updateContext({ isFooterButtonSticky: false });

    return () => {
      updateContext(DEFAULT_VALUES);
    };
  }, []);

  return (
    <LegalPageLayout
      title={data.title}
      desc={data.descMarkup}
      lastUpdated={data.lastUpdated}
    >
      <SEO {...pagesMeta.privacy} />
      {data.sections.map(({ label, markup }, index) => {
        return (
          <Section
            key={uniqueId("privacy-policy")}
            title={`${index + 1}. ${label}`}
          >
            {markup}
          </Section>
        );
      })}
    </LegalPageLayout>
  );
};

export default PrivacyPolicy;
